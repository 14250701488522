<template>
  <li class="hero-item">
    <a
      class="item-inner"
      @click.prevent="$emit('click')"
    >
      <img
        :src="heroImageSrc(hero.id)"
        alt=""
        width="148"
        class="img"
      >
      <span class="name">{{ hero.name }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'HeroItem',

  props: {
    hero: {
      type: Object,
      required: true,
      default: null
    }
  },

  methods: {
    heroImageSrc (id) {
      return require(`@images/hero/faces/lossless/${id}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-item {
  background:
    url(#{$img-path}series/hero_bg_top.png) no-repeat left top,
    url(#{$img-path}series/hero_bg_bottom.png) no-repeat left bottom;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 6px;
  padding-right: 95px;

  > .item-inner {
    background: url(#{$img-path}series/hero_bg_middle.png) repeat-y left top;
    height: 178px;
    padding-left: 78px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }
  > .item-inner > .img {
    display: block;
    margin-right: 28px;
    flex: 0 0 148px;
  }
  > .item-inner > .name {
    color: $color-base;
    font-size: $fs-xlarge;
    line-height: 1.2;
    letter-spacing: $ls-base;
  }
}
</style>
