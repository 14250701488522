<template>
  <div
    class="herolist"
    :style="pageStyle"
  >
    <series-title level="2">
      <img
        :src="seriesTitleImageSrc"
        :alt="seriesTitleText"
      >
    </series-title>

    <back-button
      ref="backButton"
      @click="goBack()"
    />

    <iscroll-view
      :options="iScrollView.options"
      :scrollerClass="{ scroller: true }"
      @touchmove.prevent.stop
      ref="indexScroller"
      class="index-list"
    >
      <ol class="list-inner">
        <index-item
          v-for="indexItem in indexList"
          :key="indexItem"
          :indexItem="indexItem"
          :selectedIndex="selectedIndex"
          @click="scrollToIndex(indexItem)"
        />
      </ol>
    </iscroll-view>

    <iscroll-view
      :options="iScrollView.options"
      :scrollerClass="{ scroller: true }"
      ref="itemScroller"
      @scroll="handleScroll"
      class="items-wrapper"
    >
      <template v-if="locale == 'zh-tw'">
        <ul>
          <hero-item
            v-for="(hero, i) in indexHeroList"
            :key="i"
            :hero="hero"
            @click="goToHero(hero.id)"
            class="list-item"
          />
        </ul>
      </template>
      <template v-else>
        <ul
          v-for="(item, i) in indexList"
          :key="i"
          :ref="`items-group-${item}`"
        >
          <hero-item
            v-for="(hero, i) in indexHeroList[item]"
            :key="i"
            :hero="hero"
            @click="goToHero(hero.id)"
            class="list-item"
          />
        </ul>
      </template>
    </iscroll-view>
    <alternative-footer
      ref="footer"
      class="footer"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Api from '@libraries/api/'
import RequestHeroes from '@requests/heroes'
import SeriesTitle from '@components/global/title.vue'
import BackButton from '@components/global/back-button.vue'
import IndexItem from '@components/series/index-item.vue'
import HeroItem from '@components/series/hero-item.vue'
import { getKeyByLabel } from '@constants/hero'
import AlternativeFooter from '@components/global/alternative-footer.vue'
import isMobile from 'ismobilejs'

const isMobileDevice = isMobile().any

export default {
  name: 'HeroList',

  components: {
    SeriesTitle,
    BackButton,
    HeroItem,
    IndexItem,
    AlternativeFooter
  },

  props: {
    locale: {
      type: String,
      required: true
    },
    themeType: {
      type: Number,
      required: true
    },
    seriesId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      $itemScroller: null,
      $indexScroller: null,
      footer: null,
      backButton: null,
      iScrollView: {
        options: {
          scrollbars: true,
          fadeScrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: !isMobileDevice,
          click: true,
          tap: true,
          disableMouse: isMobileDevice,
          disablePointer: isMobileDevice,
          disableTouch: !isMobileDevice,
          probeType: 3
        }
      },
      contentHeight: null,
      selectedIndex: null,
      indexHeroList: [],
      indexList: [],
      scrollOffsetY: 244
    }
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),

    pageStyle () {
      return {
        height: this.contentHeight || '100%'
      }
    },

    seriesTitleImageSrc () {
      return require(`@images/series/${this.locale}/txt_series_${this.seriesId}.png`)
    },

    seriesTitleText(){
      //多言語対応時にタイトルの名前を返すようにする
      return null
    },

    footerHeight () {
      return this.footer.clientHeight
    }

  },

  mounted () {
    window.scrollTo(0, 0)
    this.$itemScroller = this.$refs.itemScroller
    this.$indexScroller = this.$refs.indexScroller
    this.footer = this.$refs.footer.$el
    this.backButton = this.$refs.backButton.$el

    this.$nextTick(async () => {
      await Promise.all([
        await this.getHeroes(this.seriesId),
        await sleep(this.loaderConfig.minDisplayDuration)
      ])

      setTimeout(() => {
        this.refreshContentHeight()
        this.$itemScroller.refresh()
        this.$indexScroller.refresh()
      }, 0)

      this.hideLoader()
    })

    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),

    async getHeroes(seriesId) {
      await Api.call(new RequestHeroes({locale: this.locale, seriesId}))
        .then((heroes) => {
          if (this.locale === 'zh-tw') {
            this.indexHeroList = heroes.sort((a,b) => {
              if (a.sortLabel < b.sortLabel) return -1
              if (a.sortLabel > b.sortLabel) return 1
              return 0
            })
          } else {
            let labels = heroes.map(h => h.sortLabel)
            labels = [...new Set(labels)]
            this.indexList = labels.sort().map(l => getKeyByLabel(l).toLowerCase())
            this.indexHeroList = {}
            for (const item of labels) {
              const key = getKeyByLabel(item).toLowerCase()
              const filterdBySortLabel = heroes.filter(h => h.sortLabel === item)
              this.indexHeroList[key] = filterdBySortLabel.sort((a, b) => {
                if (a.kana < b.kana) { return -1 }
                if (a.kana > b.kana) { return 1 }
                return 0
              })
            }
            this.selectedIndex = this.indexList.length > 0 ? this.indexList[0] : null
          }
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    refreshContentHeight () {
      this.contentHeight = `${window.innerHeight}px`
    },

    scrollToIndex (index) {
      // @NOTE スクロール中は弾く
      if (this.$itemScroller.iscroll.isAnimating) {
        return
      }

      const itemGroup = this.$refs[`items-group-${index}`][0]
      this.selectedIndex = index
      this.$itemScroller.scrollToElement(itemGroup, 400, 0, -this.scrollOffsetY)
    },

    goBack () {
      this.$router.push({
        name: 'Series',
        query: { series: this.seriesId }
      })
    },

    goToHero (heroId) {
      this.$router.push({
        name: 'Hero',
        params: { heroId }
      })
    },

    handleScroll (iscroll) {
      const dy = iscroll.y - iscroll.maxScrollY
      this.updateFlowElTransform(dy)
    },

    updateFlowElTransform (dy) {
      if (dy > this.footerHeight) {
        this.setFlowElTransform(this.footer, '')
        this.setFlowElTransform(this.backButton, '')
        return
      }
      // @NOTE IEは%計算ができない
      this.setFlowElTransform(this.footer, `translateY(${dy}px) translateZ(0)`)
      this.setFlowElTransform(this.backButton, `translateY(${dy - this.footerHeight}px) translateZ(0)`)
    },

    setFlowElTransform (el, val) {
      el.style.transform =
      el.style.webkitTransform = val
    },

    handleResize () {
      this.refreshContentHeight()
    }
  }
}
</script>

<style lang="scss" scoped>
$herolist-z-index: (
  title: 10,
  back-button: 15,
  index-list: 5,
  footer: 20
);

.herolist {
  min-height: 100%;

  > .title {
    position: absolute;
    top: 40px + 111px;
    left: 0;
    right: 0;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
    z-index: map-get($herolist-z-index, title);
  }

  > .items-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  > .index-list {
    position: absolute;
    top: 111px;
    right: 30px;
    bottom: 0;
    z-index: map-get($herolist-z-index, index-list);
  }

  > .back-button {
    position: absolute;
    right: -2px;
    bottom: 20px;
    transform: translateZ(0);
    z-index: map-get($herolist-z-index, back-button);
  }
}

  .index-list {
    background: url(#{$img-path}series/characters-bar_bg.png) repeat-y;
    box-shadow:
      inset 0 0 4px 0 rgb(255, 192, 40),
      0px 0px 5px 0px rgba(0, 0, 0, 0.65);
    width: 60px;
    height: 100%;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);

    .scroller {
      touch-action: none;
      padding-top: 119px;
      padding-bottom: 400px;
    }
      .list-inner {
        > .index-item > .index-a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
  }

  .items-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    /deep/ .iScrollIndicator {
      background-color: rgba(10, 30, 50, 0.65) !important;
    }

    .scroller {
      padding-top: 133px + 111px;
      padding-bottom: 100px;
      touch-action: none;

      /deep/ * {
        touch-action: none;
      }
    }
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(100%) translateZ(0);
    z-index: map-get($herolist-z-index, footer);
  }
</style>
