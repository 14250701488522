<template>
  <li
    v-if="indexItem"
    class="index-item"
  >
    <img
      v-if="indexItem === selectedIndex"
      :src="indexImageS(indexItem)"
    >
    <a
      v-else
      class="index-image"
      @click.prevent="$emit('click')"
    >
      <img
        :src="indexImage(indexItem)"
        draggable="false"
      >
    </a>
  </li>
</template>

<script>
export default {
  name: 'IndexItem',

  props: {
    indexItem: {
      type: String,
      default: null
    },
    selectedIndex: {
      type: String,
      default: null
    }
  },

  computed: {
    localeId () {
      return this.$route.params.lang === 'ja-jp' ? 'ja-jp' : 'en-us'
    }
  },

  methods: {
    indexImageS (indexItem) {
      return require(`@images/series/${this.localeId}/index_txt_${indexItem}_s.png`)
    },

    indexImage (indexItem) {
      return require(`@images/series/${this.localeId}/index_txt_${indexItem}.png`)
    }
  },
}
</script>

<style lang="scss" scoped>
.index-item {
  width: 60px;
  height: 56px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .index-image {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }
}
</style>